import { Rectangle } from '../../maths/shapes/Rectangle.mjs';
import { Texture } from '../../rendering/renderers/shared/texture/Texture.mjs';
import { AbstractBitmapFont } from './AbstractBitmapFont.mjs';
import { BitmapFontManager } from './BitmapFontManager.mjs';
"use strict";
class BitmapFont extends AbstractBitmapFont {
  constructor(options, url) {
    super();
    const {
      textures,
      data
    } = options;
    Object.keys(data.pages).forEach(key => {
      const pageData = data.pages[parseInt(key, 10)];
      const texture = textures[pageData.id];
      this.pages.push({
        texture
      });
    });
    Object.keys(data.chars).forEach(key => {
      const charData = data.chars[key];
      const textureSource = textures[charData.page].source;
      const frameReal = new Rectangle(charData.x, charData.y, charData.width, charData.height);
      const texture = new Texture({
        source: textureSource,
        frame: frameReal
      });
      this.chars[key] = {
        id: key.codePointAt(0),
        xOffset: charData.xOffset,
        yOffset: charData.yOffset,
        xAdvance: charData.xAdvance,
        kerning: charData.kerning ?? {},
        texture
      };
    });
    this.baseRenderedFontSize = data.fontSize;
    this.baseMeasurementFontSize = data.fontSize;
    this.fontMetrics = {
      ascent: 0,
      descent: 0,
      fontSize: data.fontSize
    };
    this.baseLineOffset = data.baseLineOffset;
    this.lineHeight = data.lineHeight;
    this.fontFamily = data.fontFamily;
    this.distanceField = data.distanceField ?? {
      type: "none",
      range: 0
    };
    this.url = url;
  }
  /** Destroys the BitmapFont object. */
  destroy() {
    super.destroy();
    for (let i = 0; i < this.pages.length; i++) {
      const {
        texture
      } = this.pages[i];
      texture.destroy(true);
    }
    this.pages = null;
  }
  /**
   * Generates a bitmap-font for the given style and character set
   * @param options - Setup options for font generation.
   * @returns Font generated by style options.
   * @example
   * import { BitmapFont, BitmapText } from 'pixi.js';
   *
   * BitmapFont.install('TitleFont', {
   *     fontFamily: 'Arial',
   *     fontSize: 12,
   *     strokeThickness: 2,
   *     fill: 'purple',
   * });
   *
   * const title = new BitmapText({ text: 'This is the title', fontFamily: 'TitleFont' });
   */
  static install(options) {
    BitmapFontManager.install(options);
  }
  /**
   * Uninstalls a bitmap font from the cache.
   * @param {string} name - The name of the bitmap font to uninstall.
   */
  static uninstall(name) {
    BitmapFontManager.uninstall(name);
  }
}
export { BitmapFont };
